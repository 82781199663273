import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppBar from 'material-ui/AppBar';
import Avatar from 'material-ui/Avatar';
import Toolbar from 'material-ui/Toolbar';
import Typography from 'material-ui/Typography';
import Icon from 'material-ui/Icon';
import IconButton from 'material-ui/IconButton';
import Menu, { MenuItem } from 'material-ui/Menu';
import MoreVertIcon from 'material-ui-icons/MoreVert';
import { Link } from 'react-router-dom';
import '../css/headerbar.css';

class HeaderBar extends Component {
    constructor(props) {
        super(props);
        this.state = { currentusername: '', popupmenu: { open: false, anchorEl: undefined } };
        this.handleMenuIconClick = this.handleMenuIconClick.bind(this);
        this.handleRequestMenuClose = this.handleRequestMenuClose.bind(this);
    }

    componentDidMount() {
        this.setState({ currentusername: this.props.user.name });
    };

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.user.name !== nextProps.user.name)
            return true;
        else
            return false;
    };

    handleMenuIconClick(event) {
        let popupmenu = Object.assign({}, this.state.popupmenu, { open: true, anchorEl: event.currentTarget });
        this.setState({ popupmenu: popupmenu });
        this.forceUpdate();
    };

    handleRequestMenuClose() {
        let popupmenu = Object.assign({}, this.state.popupmenu, { open: false });
        this.setState({ popupmenu: popupmenu });
        this.forceUpdate();
    };

    render() {
        let appBarRightComp = <div></div>;
        const currentUser = this.props.user;
        if (currentUser.islogged) {
            const popupstate = this.state.popupmenu;
            const avatar = currentUser.avatar !== '' ?
                <Avatar src={'/img/' + currentUser.avatar} />
                : <Avatar style={{ float: 'left', backgroundColor: '#fff' }}><Icon className="user-avatar">person</Icon></Avatar>;
            const username = <span className="currentusername">{currentUser.name}</span>;
            //const mediabadge = <Badge style={{ marginRight: '15px' }} tooltip="Video caricati" badgeContent={currentUser.mediacount} color="accent"><Icon tooltip="Video caricati" title="Video Caricati" color='contrast'>video_library</Icon></Badge>;
            const iconMenu = <IconButton aria-label="More" aria-owns={popupstate.open ? 'long-menu' : null}
                aria-haspopup="true"
                onClick={this.handleMenuIconClick}
            >
                <MoreVertIcon color={'#FFF'} />
            </IconButton>;

            const popupMenu = <Menu
                id="long-menu"
                anchorEl={popupstate.anchorEl}
                open={popupstate.open}
                onRequestClose={this.handleRequestMenuClose}
            >
                <MenuItem onClick={this.props.onLogout}>Logout</MenuItem>
            </Menu>;

            appBarRightComp = <div>{avatar}{username}{iconMenu}{popupMenu}</div>;
        }
        return (
            <div>
                <AppBar position="static" className="mainappbar">
                    <Toolbar disableGutters>
                        {/*currentUser.islogged &&
                            <IconButton style={{marginLeft:12, marginRight:20}} color="contrast" aria-label="Menu">
                                <MenuIcon />
                            </IconButton>
                        */}
                        <Typography type="title" style={{ flex: 'auto', textAlign: 'left', marginLeft: '10px' }} color="inherit" >
                            <Link to="/" color="contrast" className="flatlink" >
                                <div className="logocontent">
                                    <img className="logo" alt="VoloSpeech" src="/img/logo_color_xs.png" />
                                </div>
                            </Link>
                        </Typography>
                        {appBarRightComp}
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}

HeaderBar.propTypes = {
    user: PropTypes.object.isRequired,
    onLogout: PropTypes.func.isRequired
};

export default HeaderBar;
