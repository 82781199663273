import React, { Component } from 'react';
import APPCONFIG from '../data/appconfig';
import PropTypes from 'prop-types';
import Radio, { RadioGroup } from 'material-ui/Radio';
import { FormControl, FormControlLabel } from 'material-ui/Form';
import Button from 'material-ui/Button';
import Dialog, {
    DialogActions,
    DialogContent,
    DialogContentText,
} from 'material-ui/Dialog';
import { CircularProgress } from 'material-ui/Progress';
import '../css/newtrans.css';
import $ from 'jquery';

class NewTrans extends Component {
    constructor(props) {
        super(props);
        this.state = { transtypes: [], selectedspeechmodel: '', alertopened: false };
        this.loadTransType = this.loadTransType.bind(this);
        this.doTranscription = this.doTranscription.bind(this);
        this.updateSelectedTransType = this.updateSelectedTransType.bind(this);
        this.getRadioButton = this.getRadioButton.bind(this);
        this.handleAlertClose = this.handleAlertClose.bind(this);
    }

    handleAlertClose() {
        this.setState({ alertopened: false });
        this.props.onNewTransAdded();
    }

    loadTransType() {
        $.ajax({
            type: 'GET',
            url: APPCONFIG.apibaseurl + '/speechmodel/list',
            headers: {
                'AuthToken': this.props.user.authtoken
            },
            success: function (data) {
                if (data.IsSucceeded) {
                    this.setState({ transtypes: data.allspeechmodel });
                    if (data.allspeechmodel.length > 0)
                        this.setState({ selectedspeechmodel: data.allspeechmodel[0].SpeechModelGuid });
                }
            }.bind(this),
            error: function (xhr, status, err) {
                console.error(status, err.toString());
            }
        });
    }

    doTranscription() {
        let param = {};
        param.job = [];
        param.job.push({ FileGuid: this.props.fileGuid, SpeechModelGuid: this.state.selectedspeechmodel });
        $.ajax({
            type: 'POST',
            url: APPCONFIG.apibaseurl + '/job/setintranscriptionqueue',
            headers: {
                'Content-Type': 'application/json',
                'AuthToken': this.props.user.authtoken
            },
            data: JSON.stringify(param),
            //dataType: 'json',
            success: function (data) {
                if (data.IsSucceeded) {
                    //this.setState({ alertopened: true });
                    this.props.onNewTransAdded();
                }
            }.bind(this),
            error: function (xhr, status, err) {
                console.error(status, err.toString());
            }
        });
    }

    updateSelectedTransType(event, value) {
        this.setState({ selectedspeechmodel: value });
    };

    componentDidMount() {
        this.loadTransType();
    };

    getRadioButton(trans, index) {
        const rbLabel = <div>
            <span className="rb-title"><img alt='flag' className='rb-transflag' src={'/img/flag/' + trans.Language.toLowerCase() + '.png'} /> {trans.Name}</span>
            <br />
            <span className="rb-description">{trans.Description}</span>
        </div>
        const rb = <FormControlLabel key={index} value={trans.SpeechModelGuid} control={<Radio />} label={rbLabel} />;
        return rb;
    }

    render() {
        let transtype = this.state.transtypes;
        if (!transtype || transtype.length === 0)
            return null;
        else {

            var rbList = transtype.map((trans, index) => this.getRadioButton(trans, index));
            return (
                <div>
                    <div style={{ 'marginBottom': '10px' }}>Modelli di trascrizione disponibili:</div>
                    <div id="rbtranslist">
                        <FormControl component="fieldset" required>
                            <RadioGroup aria-label="transtyperbg" name="transtyperbg" value={this.state.selectedspeechmodel} onChange={this.updateSelectedTransType}>
                                {rbList}
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div style={{ marginTop: '10px', textAlign: 'center' }}>
                        <Button raised color="primary" onClick={this.doTranscription}>Avvia trascrizione</Button>
                    </div>
                    <Dialog open={this.state.alertopened} onRequestClose={this.handleAlertClose}>
                        <DialogContent style={{ textAlign: 'center' }}>
                            <DialogContentText>
                                <div className='alertmsg'>Trascrizione in corso</div>
                                <CircularProgress />
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleAlertClose} color="primary">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            );
        }
    }
}

NewTrans.propTypes = {
    user: PropTypes.object.isRequired,
    fileGuid: PropTypes.string.isRequired,
    onNewTransAdded: PropTypes.func.isRequired
};

export default NewTrans;