import React from 'react';
import ReactDOM from 'react-dom';
import injectTapEventPlugin from 'react-tap-event-plugin';
import App from './App';
import { CookiesProvider } from 'react-cookie';
import { createMuiTheme, MuiThemeProvider } from 'material-ui/styles';
import { BrowserRouter} from 'react-router-dom';
import registerServiceWorker from './registerServiceWorker';
import './index.css';

injectTapEventPlugin();
const voloTheme = createMuiTheme({
  "direction": "ltr",
  "palette": {
    "common": {
      "black": "#000",
      "white": "#fff",
      "transparent": "rgba(0, 0, 0, 0)",
      "fullBlack": "rgba(0, 0, 0, 1)",
      "darkBlack": "rgba(0, 0, 0, 0.87)",
      "lightBlack": "rgba(0, 0, 0, 0.54)",
      "minBlack": "rgba(0, 0, 0, 0.26)",
      "faintBlack": "rgba(0, 0, 0, 0.12)",
      "fullWhite": "rgba(255, 255, 255, 1)",
      "darkWhite": "rgba(255, 255, 255, 0.87)",
      "lightWhite": "rgba(255, 255, 255, 0.54)"
    },
    "type": "light",
    "primary": {
      "50": "#f9fdff",
      "100": "#ade8ff",
      "200": "#75d9ff",
      "300": "#2dc5ff",
      "400": "#0fbdff",
      "500": "#00adef",
      "600": "#0097d0",
      "700": "#0081b2",
      "800": "#006b93",
      "900": "#005475",
      "A100": "#effbff",
      "A200": "#448aff",
      "A400": "#23c2ff",
      "A700": "#09bbff",
      "contrastDefaultColor": "light"
    },
    "secondary": {
      "50": "#ffffff",
      "100": "#ffdfbd",
      "200": "#ffc485",
      "300": "#ffa23d",
      "400": "#ff941f",
      "500": "#ff8500",
      "600": "#d81b60",
      "700": "#c2185b",
      "800": "#a35500",
      "900": "#854500",
      "A100": "#ffffff",
      "A200": "#ff8500",
      "A400": "#ff9d33",
      "A700": "#ff911a",
      "contrastDefaultColor": "light"
    },
  }
});

ReactDOM.render((
  <BrowserRouter>
    <CookiesProvider>
      <MuiThemeProvider theme={voloTheme}><App /></MuiThemeProvider>
    </CookiesProvider>
  </BrowserRouter>
  ), document.getElementById('root'));

registerServiceWorker();
