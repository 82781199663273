import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MediaInfo from './MediaInfo';
import APPCONFIG from '../data/appconfig';
import MediaCardsList from './MediaCardsList';
import MediaCardInfo from './MediaCardInfo';
import { Row, Col, Visible, Hidden, ScreenClassRender } from 'react-grid-system';
import Paper from 'material-ui/Paper';

import '../css/watchpage.css';

const styleFunction = (screenClass, props) => {
    let marginbottom = 0;
    if (screenClass === 'xs') marginbottom = 20;

    console.log(marginbottom);
    return {
        marginBottom: marginbottom
    };
};

class Watchpage extends Component {
    constructor(props) {
        super(props);
        this.state = { mediaid: props.match.params.id };
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.id !== this.state.mediaid)
            this.setState({ mediaid: nextProps.match.params.id });
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.match.params.id !== this.state.mediaid)
            return true;
        else
            return false
    };


    render() {
        return (
            <div>
                <Row>
                    <Col xs={12} md={2} >
                        <Paper elevation={1} className="fileinfopanel">
                            <MediaCardInfo fileGuid={this.state.mediaid} user={this.props.user}></MediaCardInfo>
                        </Paper>
                    </Col>
                    <Col xs={12} md={6}>
                        <div style={{ marginBottom: '40px' }}>
                            <MediaInfo fileGuid={this.state.mediaid} user={this.props.user}></MediaInfo>
                        </div>
                    </Col>
                    <Col xs={12} md={4} className="verticallist">
                        <MediaCardsList user={this.props.user} selectedMediaId={this.state.mediaid} showVerticalList />
                    </Col>
                </Row>
            </div>
        );
    }
}

MediaInfo.propTypes = {
    user: PropTypes.object.isRequired
};

export default Watchpage;