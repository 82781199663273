import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Divider from 'material-ui/Divider';
import MediaCardsList from './MediaCardsList';
import { Row, Col } from 'react-grid-system';
import Button from 'material-ui/Button';
import AddIcon from 'material-ui-icons/Add';
import FileInputDialog from './FileInputDialog';
import Tooltip from 'material-ui/Tooltip';
import '../css/homepage.css';

class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = { usermediacount: 0, addpopupopen: false, filetoupload: null };
        this.setMediaCount = this.setMediaCount.bind(this);
        this.handleAddFilePopupRequestClose = this.handleAddFilePopupRequestClose.bind(this);
        this.handleStartingFileLoad = this.handleStartingFileLoad.bind(this);
    };

    setMediaCount(count) {
        this.setState({ usermediacount: count });
    };

    handleAddFilePopupRequestClose() {
        this.setState({ addpopupopen: false });
    };

    handleStartingFileLoad(e) {
        this.setState({ addpopupopen: true });
        var reader = new FileReader();
        var file = e.target.files[0];
        this.setState({ loading: true });

        reader.onload = function (upload) {
            const now = new Date();
            file.id = file.name + '-' + now.toString();
            this.setState({ filetoupload: file });
        }.bind(this);

        reader.readAsDataURL(file);
    };

    render() {
        return (
            <div>
                <h3 style={{ textAlign: 'left' }}>
                    <Row>
                        <Col xs={10}>
                            I miei file
                            <span className="bigbadge" color="accent">
                                {this.state.usermediacount}
                            </span>
                        </Col>
                        <Col xs={2} style={{ textAlign: 'right' }}>
                            <input type="file" accept="audio/*,video/*" id="filepicker" onChange={this.handleStartingFileLoad} style={{ display: 'none' }} />
                            <label htmlFor="filepicker">
                                <Tooltip className="customtooltip" label="Aggiungi file">
                                    <Button fab color="accent" aria-label="add" component="span" className="openfilepopup" >
                                        <AddIcon />
                                    </Button>
                                </Tooltip>
                            </label>
                            <FileInputDialog open={this.state.addpopupopen} filetoupload={this.state.filetoupload} onRequestClose={this.handleAddFilePopupRequestClose} user={this.props.user} />
                        </Col>
                    </Row>
                </h3>
                <Divider style={{ marginBottom: 30 }} />
                <MediaCardsList user={this.props.user} setMediaCount={this.setMediaCount} />
            </div>
        );
    }
};

HomePage.propTypes = {
    user: PropTypes.object.isRequired,
};

export default HomePage;