import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import HeaderBar from './components/HeaderBar';
import HomePage from './components/HomePage';
import WatchPage from './components/WatchPage';
import SearchPage from './components/SearchPage';
import LoginPage from './components/LoginPage';
import PageNotFound from './components/PageNotFound';
import { withCookies, Cookies } from 'react-cookie';
import { Container, Row, Col, Visible, Hidden, ScreenClassRender } from 'react-grid-system';

//import logo from './logo.svg';
import './css/common.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        id: '',
        authtoken: "",
        islogged: false,
        name: '',
        avatar: '',
        partnerid: ''
      }
    };
    this.setCurrentUser = this.setCurrentUser.bind(this);
    this.handleLogOut = this.handleLogOut.bind(this);
  }

  handleLogOut() {
    let currentUser = Object.assign({}, this.state.user, { name: '', avatar: '', authtoken: '', islogged: false });
    this.setState({ user: currentUser });
    const { cookies } = this.props;
    cookies.remove('vs_currentuser');
  };

  setCurrentUser(user, authtoken) {
    let currentUser = Object.assign({}, this.state.user, {
      name: user.Username,
      avatar: user.Avatar ? user.Avatar : '',
      islogged: true,
      authtoken: authtoken,
      partnerid: user.PartnerGuid,
      id: user.UserGuid
    });
    console.log(currentUser);
    this.setState({ user: currentUser });
    const { cookies } = this.props;
    let now = new Date();
    now.setHours(now.getHours() + 24);
    cookies.set('vs_currentuser', currentUser, { expires: now });
  };

  componentWillMount() {
    const { cookies } = this.props;
    if (cookies.get('vs_currentuser')) {
      this.setState({ user: cookies.get('vs_currentuser') });
    }
  };

  render() {
    return (
      <div className="App">
        <div>
          <HeaderBar user={this.state.user} onLogout={this.handleLogOut}></HeaderBar>
        </div>
        <Container fluid className="container">
          <Row>
            <Col xs={12}>
              <Switch>
                <Route exact path="/" render={() => this.state.user.islogged ? <HomePage user={this.state.user} /> : <LoginPage onLogin={this.setCurrentUser} user={this.state.user} />} />
                <Route path="/search" component={SearchPage} />
                <Route path="/watch/:id" render={({ match }) => this.state.user.islogged ? <WatchPage user={this.state.user} match={match} /> : <LoginPage onLogin={this.setCurrentUser} user={this.state.user} />} />
                <Route component={PageNotFound} />
              </Switch>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
};

App.propTypes = {
  cookies: instanceOf(Cookies).isRequired
};

export default withCookies(App);
