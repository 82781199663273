import React, { Component } from 'react';
import Input from 'material-ui/Input';
import InputLabel from 'material-ui/Input/InputLabel';
import FormControl from 'material-ui/Form/FormControl';
import FormHelperText from 'material-ui/Form/FormHelperText';
import Button from 'material-ui/Button';
import APPCONFIG from '../data/appconfig';
import { Row, Col } from 'react-grid-system';
import PropTypes from 'prop-types';
import $ from 'jquery';

class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = { username: '', password: '', error: '' };
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleLoginBtnClick = this.handleLoginBtnClick.bind(this);
    }

    handleUsernameChange(event) {
        this.setState({
            username: event.target.value,
        });
    }

    handlePasswordChange(event) {
        this.setState({
            password: event.target.value,
        });
    }

    handleLoginBtnClick(e) {
        e.preventDefault();
        var postdata = { username: this.state.username, password: this.state.password };
        $.ajax({
            type: 'POST',
            url: APPCONFIG.apibaseurl + '/user/authenticate',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(postdata),
            success: function (data) {
                //console.log(data);
                if (data.IsSucceeded)
                    return this.props.onLogin(data.user, data.AuthToken);
                else
                    this.setState({ error: 'Utente o password non corretti' });
            }.bind(this),
            error: function (xhr, status, err) {
                this.setState({ error: 'Utente o password non corretti' });
            }
        });
    }

    render() {
        return (
            <Row>
                <Col xs={12} style={{textAlign: 'center'}}>
                    <form onSubmit={this.handleLoginBtnClick}>
                        <FormControl error={this.state.error !== ''}>
                            <InputLabel htmlFor="username">Username</InputLabel>
                            <Input id="username" onChange={this.handleUsernameChange} />
                            <FormHelperText>{this.state.error}</FormHelperText>
                        </FormControl>
                        <br />
                        <FormControl error={this.state.error !== ''}>
                            <InputLabel htmlFor="password">Password</InputLabel>
                            <Input type="password" id="password" onChange={this.handlePasswordChange} />
                            <FormHelperText>{this.state.error}</FormHelperText>
                        </FormControl>
                        <br /><br />
                        <Button raised type="submit" color="primary">Accedi</Button>
                    </form>
                </Col>
            </Row>
        );
    }
}

LoginPage.propTypes = {
    onLogin: PropTypes.func.isRequired
};

export default LoginPage;