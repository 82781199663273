import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../css/videoplayer.css';
import $ from 'jquery';

class VideoPlayer extends Component {
    constructor(props) {
        super(props);
        this.setCurrentSecond = this.setCurrentSecond.bind(this);
        this.pauseCurrentSecond = this.pauseCurrentSecond.bind(this);
    }

    setCurrentSecond() {
        let video = document.getElementById('videoplayer');
        if (video)
            this.props.onCurrentSecChange(video.currentTime * 1000);
    }

    pauseCurrentSecond() {
        if (typeof this.timerID !== undefined)
            clearInterval(this.timerID);
    }

    componentDidMount() {
        let video = document.getElementById('videoplayer');
        video.onpause = function (evt) {
            clearInterval(this.timerID);
        }
        video.pause();
        video.load();
        video.currentTime = this.props.startSec;
        if (typeof this.timerID !== undefined)
            clearInterval(this.timerID);
        this.timerID = setInterval(this.setCurrentSecond, 1000);

        let sent = this.props.sentences;
        // let sentUrl = this.props.sentencesUrl;
        video.addEventListener("loadedmetadata", function () {
            let track = this.addTextTrack("captions", "On", "it");
            // track.src = sentUrl;
            for (let i = 0; i < sent.length; i++) {
                track.addCue(new VTTCue(parseInt(sent[i]['@start'] / 1000), parseInt(sent[i]['@end'] / 1000), sent[i]['#text']));
            }

            track.addEventListener("load", function () {
                track.mode = 'show';
                this.appendChild(track);
            });
        });
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.videoSrc !== nextProps.videoSrc || this.props.startSec !== nextProps.startSec)
            return true;
        return false;
    }

    componentDidUpdate(prevProps, prevState) {
        console.log('VideoPlayer did update', this.props.startSec);
        let video = document.getElementById('videoplayer');
        if (this.props.videoSrc !== prevProps.videoSrc) {
            video.pause();
            video.load();
            video.onpause = function (evt) {
                clearInterval(this.timerID);
            }
        }
        video.currentTime = this.props.startSec;
        video.play();
        if (typeof this.timerID === undefined)
            this.timerID = setInterval(this.setCurrentSecond, 1000);
    }

    render() {
        return (
            <div className="videocontent">
                <video id="videoplayer" width="100%" height="" controls autoPlay>
                    <source src={this.props.videoSrc} type="video/mp4" />
                </video>
            </div>
        );
    }
}

VideoPlayer.propTypes = {
    videoSrc: PropTypes.string.isRequired,
    videoTitle: PropTypes.string.isRequired,
    startSec: PropTypes.number.isRequired,
    onCurrentSecChange: PropTypes.func.isRequired,
    sentences: PropTypes.array.isRequired,
    sentencesUrl: PropTypes.string.isRequired
};

export default VideoPlayer;