import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog, {
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from 'material-ui/Dialog';
import Button from 'material-ui/Button';
import Icon from 'material-ui/Icon';
import { LinearProgress, CircularProgress } from 'material-ui/Progress';
import { red, green, yellow } from 'material-ui/colors';
import APPCONFIG from '../data/appconfig';
import $ from 'jquery';

//https://github.com/wubocong/react-upload-file
//https://github.com/SoAanyip/React-FileUpload

class FileInputDialog extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: false, filetitle: '', error: '', deleted: false };
        this.deleteFile = this.deleteFile.bind(this);
        this.deleteJob = this.deleteJob.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleDelete() {
        this.setState({ loading: true });
        if (this.props.objecttype === 'file')
            this.deleteFile();
        else
            this.deleteJob();
    };

    handleClose() {
        if (this.state.deleted && this.state.error === '') {
            this.props.onRequestClose(true);
            this.setState({ deleted: false });
        }
        else
            this.props.onRequestClose(false);
    };

    deleteJob() {
        //TODO aggiornare il titolo
        let postdata = { job: [{ JobGuid: this.props.objectid }] };
        $.ajax({
            type: 'POST',
            url: APPCONFIG.apibaseurl + '/job/delete',
            headers: {
                'Content-Type': 'application/json',
                'AuthToken': this.props.user.authtoken
            },
            dataType: 'json',
            data: JSON.stringify(postdata),
            success: function (data) {
                if (data.IsSucceeded)
                    this.setState({ loading: false, deleted: true });
                else
                    this.setState({ loading: false, deleted: false, error: ' Cancellazione non riuscita.' });
            }.bind(this),
            error: function (xhr, status, err) {
                console.error(status, err.toString());
                this.setState({ loading: false, deleted: false, error: ' Cancellazione non riuscita.' });
            }
        });
    };

    deleteFile() {
        //TODO aggiornare il titolo
        let postdata = { file: [{ FileGuid: this.props.objectid }] };
        $.ajax({
            type: 'POST',
            url: APPCONFIG.apibaseurl + '/file/delete',
            headers: {
                'Content-Type': 'application/json',
                'AuthToken': this.props.user.authtoken
            },
            dataType: 'json',
            data: JSON.stringify(postdata),
            success: function (data) {
                this.setState({ loading: false, deleted: true });
            }.bind(this),
            error: function (xhr, status, err) {
                console.error(status, err.toString());
                this.setState({ loading: false, deleted: false, error: ' Cancellazione non riuscita.' });
            }
        });
    };

    render() {
        if (!this.props.objectid)
            return null;
        return (
            <Dialog open={this.props.open} onRequestClose={this.handleClose}>
                <DialogTitle className="dialogtitle">Elimina {this.props.objecttype === 'file' ? 'file' : 'trascrizione'}</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ textAlign: 'center', minWidth: '300px', padding: '20px 10px' }}>
                        {this.state.loading &&
                            <div>
                                <div className='alertmsg'>eliminzaione in corso</div>
                                <CircularProgress />
                            </div>}
                        {!this.state.loading && !this.state.deleted && this.state.error === '' &&
                            <div>
                                <div>
                                    <div className='alertmsg'>Eliminare
                                    {this.props.objecttype === 'file' ? ' il file selezionato' : 'la trascrizione selezionata'}?
                                    </div>
                                </div>
                                <Button style={{ marginTop: 10, backgroundColor: red[500] }} raised onClick={this.handleDelete} >
                                    Elimina
                                </Button>
                                <Button style={{ marginTop: 10 }} raised color="default" onClick={this.handleClose} >
                                    annulla
                                </Button>
                            </div>}
                        {!this.state.loading && this.state.deleted && this.state.error === '' &&
                            <div>
                                <div>
                                    <div className='alertmsg'>
                                        <Icon style={{ color: green[500] }}>check</Icon>
                                        {this.props.objecttype === 'file' ? 'File eliminato ' : 'Trascrizione eliminata '}correttamente
                                    </div>
                                </div>
                                <Button style={{ marginTop: 10 }} color='primary' raised onClick={this.handleClose} >
                                    ok
                                </Button>
                            </div>}
                        {!this.state.loading && !this.state.deleted && this.state.error !== '' &&
                            <div>
                                <div>
                                    <div className='alertmsg'>
                                        <Icon style={{ color: yellow[500] }}>warning</Icon>
                                        {this.state.error}
                                    </div>
                                </div>
                                <Button style={{ marginTop: 10 }} raised color="default" onClick={this.handleClose} >
                                    chiudi
                                </Button>
                            </div>}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

FileInputDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired,
    objectid: PropTypes.string.isRequired,
    objecttype: PropTypes.string.isRequired, // file || job
    onRequestClose: PropTypes.func
};

export default FileInputDialog;