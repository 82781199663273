import React, { Component } from 'react';
import DeleteDialog from './DeleteDialog'
import PropTypes from 'prop-types';
import { Row, Col } from 'react-grid-system';
import Icon from 'material-ui/Icon';
import Divider from 'material-ui/Divider';
import Button from 'material-ui/Button';
import APPCONFIG from '../data/appconfig';
import $ from 'jquery';
import '../css/mediacardinfo.css';

class MediaCardInfo extends Component {
    constructor(props) {
        super(props);
        this.state = { fileinfo: {}, deletedialogopen: false };
        this.showPrettyBytes = this.showPrettyBytes.bind(this);
        this.formatSeconds = this.formatSeconds.bind(this);
        this.loadFileInfo = this.loadFileInfo.bind(this);
        this.handleDeleteDialogRequestClose = this.handleDeleteDialogRequestClose.bind(this);
        this.handleDeleteDialogRequestOpen = this.handleDeleteDialogRequestOpen.bind(this);
    };

    handleDeleteDialogRequestClose(reload) {
        this.setState({ deletedialogopen: false });
        if (reload)
            window.location.href = '/';
    };

    handleDeleteDialogRequestOpen() {
        this.setState({ deletedialogopen: true });
    };

    showPrettyBytes(num) {
        const UNITS = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        if (!Number.isFinite(num))
            console.log('Expected a finite number');

        const neg = num < 0;

        if (neg)
            num = -num;

        if (num < 1)
            return (neg ? '-' : '') + num + ' B';

        const exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), UNITS.length - 1);
        const numStr = Number((num / Math.pow(1000, exponent)).toPrecision(3));
        const unit = UNITS[exponent];

        return (neg ? '-' : '') + numStr + ' ' + unit;
    };

    formatSeconds(seconds) {
        let m = Math.floor(seconds / 60);
        let s = seconds % 60;
        let h = Math.floor(m / 60);
        m = m % 60;

        if (s < 10)
            s = '0' + s;
        if (m < 10)
            m = '0' + m;
        if (h < 10)
            h = h === 0 ? '' : '0' + h;

        if (h === '')
            return m + ':' + s;

        return h + ':' + m + ':' + s;
    };

    loadFileInfo() {
        var postdata = { file: [{ FileGuid: this.props.fileGuid }] };
        $.ajax({
            type: 'POST',
            url: APPCONFIG.apibaseurl + '/file/info',
            headers: {
                'Content-Type': 'application/json',
                'AuthToken': this.props.user.authtoken
            },
            dataType: 'json',
            data: JSON.stringify(postdata),
            success: function (data) {
                if (data.file !== null) {
                    this.setState({ fileinfo: data.file[0] });
                }
            }.bind(this),
            error: function (xhr, status, err) {
                console.error(status, err.toString());
            }
        });
    };


    componentDidMount() {
        if (this.props.fileGuid !== '') {
            this.loadFileInfo();
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if ((this.props.fileGuid !== '') && (prevProps.fileGuid !== this.props.fileGuid)) {
            this.loadFileInfo();
        }
    };

    render() {
        let info = this.state.fileinfo;
        return (
            <div>
                <div className="fileattr"><Icon>insert_drive_file</Icon> Tipo file: <i>{info.Format}</i></div>
                <div className="fileattr"><Icon>save</Icon> Dimensioni: <i>{this.showPrettyBytes(parseInt(info.Size, 10))}</i></div>
                <div className="fileattr"><Icon>av_timer</Icon> Durata: <i>{this.formatSeconds(info.Duration)}</i></div>
                <Divider />
                <div className="btnlist">
                    {info.MP4FileLink && <Button color="primary" href={info.MP4FileLink}><Icon>file_download</Icon> Scarica Video</Button>}
                    {info.WAVFileLink && <Button color="primary" href={info.WAVFileLink}><Icon>file_download</Icon> Scarica Audio</Button>}

                    <Button color="primary" style={{ color: '#00ADEF' }} onClick={this.handleDeleteDialogRequestOpen} ><Icon>delete</Icon> Elimina File</Button>
                </div>
                {/* <div className="btnlist">
                    {info.MP4FileLink && <Button color="primary" href={info.MP4FileLink}><Icon>file_download</Icon> Scarica Video</Button>}
                    {info.WAVFileLink && <Button color="primary" href={info.WAVFileLink}><Icon>file_download</Icon> Scarica Audio</Button>}
                    <Button color="primary" style={{ color: '#00ADEF' }} onClick={this.handleDeleteDialogRequestOpen} ><Icon>delete</Icon> Elimina File</Button>
                </div> */}
                <DeleteDialog open={this.state.deletedialogopen} user={this.props.user} objectid={this.props.fileGuid} objecttype='file' onRequestClose={this.handleDeleteDialogRequestClose} />
            </div>
        );
    }
}

MediaCardInfo.propTypes = {
    fileGuid: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired
}

export default MediaCardInfo;