import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DeleteDialog from './DeleteDialog'
import Time from 'react-time-format';
import Icon from 'material-ui/Icon';
import { red, yellow, green } from 'material-ui/colors';
import { Link } from 'react-router-dom';
import IconButton from 'material-ui/IconButton';
import Menu, { MenuItem } from 'material-ui/Menu';
import MoreVertIcon from 'material-ui-icons/MoreVert';
import { Row, Col } from 'react-grid-system';
import Tooltip from 'material-ui/Tooltip';
import APPCONFIG from '../data/appconfig';
import '../css/mediacard.css';

class MediaCard extends Component {
    constructor(props) {
        super(props);
        this.state = { popupmenu: { open: false, anchorEl: undefined }, deletedialogopen: false };
        this.showPrettyBytes = this.showPrettyBytes.bind(this);
        this.formatSeconds = this.formatSeconds.bind(this);
        this.playMedia = this.playMedia.bind(this);
        this.getStatus = this.getStatus.bind(this);
        this.getCardBkgUrl = this.getCardBkgUrl.bind(this);
        this.handleMenuIconClick = this.handleMenuIconClick.bind(this);
        this.handleRequestMenuClose = this.handleRequestMenuClose.bind(this);
        this.handleDeleteDialogRequestClose = this.handleDeleteDialogRequestClose.bind(this);
        this.handleDeleteDialogRequestOpen = this.handleDeleteDialogRequestOpen.bind(this);
    };

    handleDeleteDialogRequestClose(reload) {
        this.setState({ deletedialogopen: false });
        //if (window.location.pathname.indexOf('/watch/') > -1)
        //    window.location.href = '/';
        if (reload && this.props.reloadFunction)
            this.props.reloadFunction();
    };

    handleDeleteDialogRequestOpen() {
        this.setState({ deletedialogopen: true });
    };

    showPrettyBytes(num) {
        const UNITS = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        if (!Number.isFinite(num))
            console.log('Expected a finite number');

        const neg = num < 0;

        if (neg)
            num = -num;

        if (num < 1)
            return (neg ? '-' : '') + num + ' B';

        const exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), UNITS.length - 1);
        const numStr = Number((num / Math.pow(1000, exponent)).toPrecision(3));
        const unit = UNITS[exponent];

        return (neg ? '-' : '') + numStr + ' ' + unit;
    };

    playMedia(e) {
        e.preventDefault();
        //link alla pagina watch
    };

    formatSeconds(seconds) {
        let m = Math.floor(seconds / 60);
        let s = seconds % 60;
        let h = Math.floor(m / 60);
        m = m % 60;

        if (s < 10)
            s = '0' + s;
        if (m < 10)
            m = '0' + m;
        if (h < 10)
            h = h === 0 ? '' : '0' + h;

        if (h === '')
            return m + ':' + s;

        return h + ':' + m + ':' + s;
    };

    getStatus(statuscode) {
        switch (statuscode) {
            case 0:
                return <div className="blink"><Tooltip className="customtooltip" label="Upload file in corso"><Icon style={{ color: yellow[500] }}>file_upload</Icon></Tooltip></div>;
            case 1:
                return <div className="blink help"><Tooltip className="customtooltip" label="Upload file eseguito"><Icon style={{ color: green[500] }}>file_upload</Icon></Tooltip></div>;
            case 2:
                return <div className="blink help"><Tooltip className="customtooltip" label="Conversione file in corso"><Icon style={{ color: yellow[500] }}>cached</Icon></Tooltip></div>;
            case 3:
                return <div className="help"><Tooltip className="customtooltip" label="Conversione eseguita con successo"><Icon style={{ color: green[500] }}>check</Icon></Tooltip></div>;
            case 32:
                return <div className="help"><Tooltip className="customtooltip" label="Errore durante la conversione"><Icon style={{ color: red[500] }}>error_outline</Icon></Tooltip></div>;
            default:
                return '';
        }
    };

    getCardBkgUrl() {
        let media = this.props.media;
        if ((media.Format === 'amr') || (media.Format === 'wav') || (media.Format === 'mp3'))
            return '/img/thumbs/audiothumb.jpg';
        if (media.Status === 3)
            return APPCONFIG.createthumbbaseurl + '?partner_id=' + this.props.user.partnerid + '&user_id=' + this.props.user.id + '&file_id=' + media.FileGuid + '&sec=0';

        return '/img/thumbs/videothumb.jpg';
    };

    handleMenuIconClick(event) {
        let popupmenu = Object.assign({}, this.state.popupmenu, { open: true, anchorEl: event.currentTarget });
        this.setState({ popupmenu: popupmenu });
    };

    handleRequestMenuClose() {
        let popupmenu = Object.assign({}, this.state.popupmenu, { open: false });
        this.setState({ popupmenu: popupmenu });
    };

    render() {
        let media = this.props.media;
        const mediathumb = <div className={media.Status === 3 ? "mediathumb thumblink" : "mediathumb"} style={{ backgroundImage: "url('" + this.getCardBkgUrl() + "')" }}>
            <div className="mediastatus mediathumblabel">{this.getStatus(media.Status)}</div>
            {media.Format && <div className="mediaformat mediathumblabel">{media.Format}</div>}
            {media.Size > 0 && <div className="mediasize mediathumblabel">{this.showPrettyBytes(parseInt(media.Size, 10))}</div>}
            {media.Duration > 0 && <div className="mediaduration mediathumblabel">{this.formatSeconds(media.Duration)}</div>}
        </div>;
        const popupstate = this.state.popupmenu;

        const cardimg = <div>{media.Status === 3 && <Link to={"/watch/" + media.FileGuid}>{mediathumb}</Link>}
            {media.Status !== 3 && mediathumb}</div>;

        const mediatext = <div className="mediatext">
            <div className="mediatitle">
                {media.Status === 3 && <Link to={"/watch/" + media.FileGuid}>{media.Title ? media.Title : media.Name}</Link>}
                {media.Status !== 3 && (media.Title ? media.Title : media.Name)}
                {this.props.displayMenuIcon &&
                    <div style={{ float: 'right' }}>
                        <IconButton
                            aria-label="More"
                            aria-owns={this.state.popupmenu.open ? 'card-menu' : null}
                            aria-haspopup="true"
                            onClick={this.handleMenuIconClick}
                            className="autosizebtn"
                        >
                            <MoreVertIcon className="moreverticon" />
                        </IconButton>
                        <Menu
                            id="card-menu"
                            className="cardpopupmenu"
                            anchorEl={popupstate.anchorEl}
                            open={popupstate.open}
                            onRequestClose={this.handleRequestMenuClose}>
                            {media.MP4FileLink !== '' && <MenuItem selected={false}><Icon className="popupmenuicon">file_download</Icon><a href={media.MP4FileLink}>Scarica Video</a></MenuItem>}
                            {media.WAVFileLink && <MenuItem selected={false}><Icon className="popupmenuicon">file_download</Icon><a href={media.WAVFileLink}>Scarica Audio</a></MenuItem>}
                            {/* <MenuItem selected={false}><Icon className="popupmenuicon">add_circle</Icon>Aggiungi Tag</MenuItem> */}
                            <MenuItem selected={false} onClick={this.handleDeleteDialogRequestOpen}><Icon className="popupmenuicon">delete</Icon>Elimina</MenuItem>
                        </Menu>
                    </div>
                }
            </div>
            <div className="mediainfo">
                <div><Time value={media.CreationDate} format="DD/MM/YYYY hh:mm" /></div>
            </div>
        </div>;

        return (
            <div>
                {!this.props.twoColumnLayout &&
                    (<div className="mediacard">
                        {cardimg}{mediatext}
                    </div>)
                }
                {this.props.twoColumnLayout &&
                    (
                        <Row className="twocolumnsmediacar">
                            <Col xs={6}>{cardimg}</Col>
                            <Col xs={6}>{mediatext}</Col>
                        </Row>
                    )
                }
                <DeleteDialog open={this.state.deletedialogopen} user={this.props.user} objectid={media.FileGuid} objecttype='file' onRequestClose={this.handleDeleteDialogRequestClose} />
            </div>
        );
    }
};

MediaCard.propTypes = {
    media: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    twoColumnLayout: PropTypes.bool,
    reloadFunction: PropTypes.func,
    displayMenuIcon: PropTypes.bool
};

export default MediaCard;