import React, { Component } from 'react';
import PropTypes from 'prop-types';
import APPCONFIG from '../data/appconfig';
import AppBar from 'material-ui/AppBar';
import Tabs, { Tab } from 'material-ui/Tabs';
import Icon from 'material-ui/Icon';
import VideoPlayer from './VideoPlayer';
import Time from 'react-time-format';
import NewTrans from './NewTrans';
import TransBox from './TransBox';
import Paper from 'material-ui/Paper';
import Divider from 'material-ui/Divider';
import Button from 'material-ui/Button';
import ReactInterval from 'react-interval';
import DeleteDialog from './DeleteDialog'
import { Row, Col } from 'react-grid-system';
import Chip from 'material-ui/Chip';
import Dialog, {
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from 'material-ui/Dialog';
import $ from 'jquery';
import '../css/mediainfo.css';

class MediaInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fileinfo: '',
            deletedialogopen: false,
            intervalenabled: true,
            jobs: [],
            speechCodes: [],
            slideIndex: 0,
            player: { startsec: 0, currentsec: 0 },
            transModify: false,
            saveDone: false
        };

        this.loadFileInfo = this.loadFileInfo.bind(this);
        this.getJobStatus = this.getJobStatus.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.getJobTabHeader = this.getJobTabHeader.bind(this);
        this.getJobTabContent = this.getJobTabContent.bind(this);
        this.loadXmlFileTranscription = this.loadXmlFileTranscription.bind(this);
        this.transSerialize = this.transSerialize.bind(this);
        this.handleCurrentSecChange = this.handleCurrentSecChange.bind(this);
        this.loadSpeechModeCode = this.loadSpeechModeCode.bind(this);
        this.handleTransAdded = this.handleTransAdded.bind(this);
        this.loadJobs = this.loadJobs.bind(this);
        this.playOnSec = this.playOnSec.bind(this);
        this.handleDeleteDialogRequestClose = this.handleDeleteDialogRequestClose.bind(this);
        this.handleDeleteDialogRequestOpen = this.handleDeleteDialogRequestOpen.bind(this);
        this.handleRequestTagDelete = this.handleRequestTagDelete.bind(this);
        this.handleTextareaChange = this.handleTextareaChange.bind(this);
        this.handleJsonSave = this.handleJsonSave.bind(this);
        this.enableModifyState = this.enableModifyState.bind(this);
        this.disableModifyState = this.disableModifyState.bind(this);
        this.hideSaveDone = this.hideSaveDone.bind(this);
        this.isNumber = this.isNumber.bind(this);
        this.addJsonTrans = this.addJsonTrans.bind(this);
        this.removeJsonTrans = this.removeJsonTrans.bind(this);
    }

    handleDeleteDialogRequestClose(reload) {
        this.setState({ deletedialogopen: false });
        this.loadJobs();
    };

    handleDeleteDialogRequestOpen() {
        this.setState({ deletedialogopen: true });
    };

    handleCurrentSecChange(millisec) {
        let player = Object.assign({}, this.state.player, { currentsec: millisec });
        this.setState({ player: player });
    }

    handleTabChange(event, value) {
        this.setState({ slideIndex: value });
        if (value < this.state.jobs.length && !this.state.jobs[value].transcription) {
            this.loadXmlFileTranscription(this.state.jobs[value].XMLFileLink, value);
        }
    };

    handleRequestTagDelete(data) {
    };

    playOnSec(e) {
        e.preventDefault();
        const startsec = Math.floor(parseInt(e.target.getAttribute('start')) / 1000);
        let player = Object.assign({}, this.state.player, { startsec: startsec });
        this.setState({ player: player });
    }

    transSerialize(xmlDoc, index) {
        let xmlsentences = xmlDoc.getElementsByTagName('sentence');
        if (!xmlsentences) {
            console.log('xml nullo');
            return;
        }

        let objsentences = [];
        for (var i = 0; i < xmlsentences.length; i++) {
            let xmlitems = xmlsentences[i].getElementsByTagName('item');
            let objitems = [];
            for (var j = 0; j < xmlitems.length; j++) {
                const startMilliSec = xmlitems[j].getAttribute('start');
                const endMilliSec = xmlitems[j].getAttribute('end');
                let innertext = '';
                switch (j) {
                    case 0:
                        innertext = xmlitems[j].textContent.charAt(0).toUpperCase() + xmlitems[j].textContent.slice(1) + ' ';
                        break;
                    case xmlitems.length - 1:
                        innertext = xmlitems[j].textContent + '. ';
                        break;
                    default:
                        innertext = xmlitems[j].textContent + ' ';
                }
                objitems.push({ start: startMilliSec, end: endMilliSec, text: innertext });
            }
            objsentences.push({ start: xmlsentences[i].getAttribute('start'), end: xmlsentences[i].getAttribute('end'), items: objitems });
        }

        let statejobs = this.state.jobs;
        statejobs[index].transcription = objsentences;
        this.setState({ jobs: statejobs });
    }

    handleTransAdded() {
        //this.setState({slideIndex:this.state.jobs.length});
        this.setState({ slideIndex: 0 });
        this.loadJobs();
    };

    loadJobs() {
        var postdata = { file: [{ FileGuid: this.props.fileGuid }] };
        $.ajax({
            type: 'POST',
            url: APPCONFIG.apibaseurl + '/file/info',
            headers: {
                'Content-Type': 'application/json',
                'AuthToken': this.props.user.authtoken
            },
            dataType: 'json',
            data: JSON.stringify(postdata),
            success: function (data) {
                if (data.file !== null) {
                    const index = this.state.slideIndex;

                    const differentJobsLength = data.job.length != this.state.jobs.length;
                    const differentXMLFiles = this.state.jobs.length > 0 && data.job[index] != undefined && data.job[index].XMLFileLink != undefined && this.state.jobs[index] != undefined && this.state.jobs[index].XMLFileLink == undefined;
                    const differentJsonFiles = this.state.jobs.length > 0 && data.job[index] != undefined && data.job[index].JSONObjectLink != undefined && this.state.jobs[index] != undefined && this.state.jobs[index].JSONObjectLink == undefined;

                    if (differentJobsLength || differentXMLFiles || differentJsonFiles) {
                        this.setState({ jobs: data.job });
                    }

                    if (this.state.jobs && this.state.jobs[index] != undefined) {
                        if (this.state.jobs[index].transcription == undefined) {
                            this.loadXmlFileTranscription(this.state.jobs[index].XMLFileLink, index);
                        }
                        if (this.state.jobs[index].JsonSentences == undefined) {
                            this.loadJsonFileTranscription(this.state.jobs[index].JSONObjectLink, index);
                        }
                    }

                    this.setState({ intervalenabled: true });
                }
            }.bind(this),
            error: function (xhr, status, err) {
                console.error(status, err.toString());
            }
        });
    };

    loadFileInfo() {
        var postdata = { file: [{ FileGuid: this.props.fileGuid }] };
        $.ajax({
            type: 'POST',
            url: APPCONFIG.apibaseurl + '/file/info',
            headers: {
                'Content-Type': 'application/json',
                'AuthToken': this.props.user.authtoken
            },
            dataType: 'json',
            data: JSON.stringify(postdata),
            success: function (data) {
                if (data.file !== null) {
                    const index = this.state.slideIndex;
                    this.setState({ fileinfo: data.file[0], jobs: data.job });
                    if (this.state.jobs.length > 0 && !this.state.jobs[index].transcription)
                        this.loadXmlFileTranscription(this.state.jobs[index].XMLFileLink, index);
                }
            }.bind(this),
            error: function (xhr, status, err) {
                console.error(status, err.toString());
            }
        });
    }

    loadSpeechModeCode() {
        $.ajax({
            type: 'GET',
            url: APPCONFIG.apibaseurl + '/speechmodel/list',
            headers: {
                'AuthToken': this.props.user.authtoken
            },
            success: function (data) {
                if (data.IsSucceeded) {
                    let speechCodes = [];
                    if (data.allspeechmodel.length > 0) {
                        for (var i = 0; i < data.allspeechmodel.length; i++)
                            speechCodes[data.allspeechmodel[i].SpeechModelGuid] = data.allspeechmodel[i].Language;
                        this.setState({ speechCodes: speechCodes });
                    }
                }
            }.bind(this),
            error: function (xhr, status, err) {
                console.error(status, err.toString());
            }
        });
    }

    getJobStatus(statuscode) {
        switch (statuscode) {
            case 1:
                return <Icon className="blink help" color='contrast'>file_upload</Icon>;
            case 2:
                return <Icon className="blink help" color='contrast'>cached</Icon>;
            case 3:
                return <Icon className="help" color='contrast'>check</Icon>;
            case 32:
                return <Icon className="help" color='contrast'>error_outline</Icon>;
            default:
                return '';
        }

        // switch (statuscode) {
        //     case 1:
        //         return <Icon tooltip="Richiesta trascrizione inviata" className="blink help" color='contrast'>file_upload</Icon>;
        //     case 2:
        //         return <Icon tooltip="Trascrizione in corso" className="blink help" color='contrast'>cached</Icon>;
        //     case 3:
        //         return <Icon tooltip="Trascrizione eseguita con successo" className="help" color='contrast'>check</Icon>;
        //     case 32:
        //         return <Icon tooltip="Errore durante la Trascrizione" className="help" color='contrast'>error_outline</Icon>;
        //     default:
        //         return '';
        // }
    }

    loadXmlFileTranscription(xmlurl, index) {
        if (!xmlurl)
            return;
        $.ajax({
            type: "GET",
            url: xmlurl,
            dataType: 'xml',
            async: true,
            cache: false,
            headers: {
                //'Access-Control-Allow-Origin': '*',
            },
            success: function (data) {
                if (data)
                    this.transSerialize(data, index);
                return;
            }.bind(this),
            error: function (xhr, status, err) {
                console.error(this.props.url, status, err.toString());
                return null;
            }.bind(this)
        });
    }

    loadJsonFileTranscription(jsonurl, index) {
        if (!jsonurl)
            return;

        $.ajax({
            type: "GET",
            url: jsonurl,
            dataType: 'json',
            async: true,
            cache: false,
            headers: {
                'Content-Type': 'application/json',
                'AuthToken': this.props.user.authtoken
            },
            success: function (data) {
                if (data) {
                    let JsonTransSentences = data['annotated-call'].annotation.type.sentence;
                    let statejobs = this.state.jobs;
                    statejobs[index].JsonSentences = JsonTransSentences;
                    this.setState({ jobs: statejobs });

                    if (this.state.actualJob === undefined) {
                        this.setState({ actualJob: statejobs[index] });
                    }
                }
            }.bind(this),
            error: function (xhr, status, err) {
                console.error(this.props.url, status, err.toString());
                return null;
            }.bind(this)
        });
    }

    getJobTabHeader(job, index) {
        const flag = <img alt='flag' className='transflag' src={'/img/flag/' + this.state.speechCodes[job.SpeechModelGuid] + '.png'} />;
        let tablabel = '';

        if (job.Status === 3 || job.Status === 32)
            tablabel = <span><Time value={job.StartTranscription} format="DD/MM/YY hh:mm" /></span>;
        else
            tablabel = <span style={{ 'fontStyle': 'italic' }}>tras. in corso</span>
        let statusIcon = <span>{this.getJobStatus(job.Status)} {flag}</span>;
        return <Tab key={index} icon={statusIcon} label={tablabel} value={index}></Tab>;
    };

    handleTextareaChange(event, type, index) {
        let actualJob = Object.assign({}, this.state.actualJob);

        if ((type == '@start' || type == '@end')) {
            if (!this.isNumber(event.target.value)) {
                actualJob.JsonSentences[index].error = true;
            }
            else {
                actualJob.JsonSentences[index].error = false;
                actualJob.JsonSentences[index][type] = parseInt(event.target.value);
            }
        }
        else {
            actualJob.JsonSentences[index][type] = event.target.value;
            actualJob.JsonSentences[index].error = false;
        }

        this.setState({ actualJob });
    }

    isNumber(str) {
        var pattern = /^\d+$/;
        return pattern.test(str);
    }

    handleJsonSave(event) {
        var anyError = false;
        var lastnumber = 0;
        for (var i = 0; i < this.state.actualJob.JsonSentences.length; i++) {
            var start = 0;
            var end = 0;

            if (this.isNumber(this.state.actualJob.JsonSentences[i]['@start'])) {
                start = parseInt(this.state.actualJob.JsonSentences[i]['@start']);
            } else { anyError = true; this.state.actualJob.JsonSentences[i].error = true; }
            if (this.isNumber(this.state.actualJob.JsonSentences[i]['@end'])) {
                end = parseInt(this.state.actualJob.JsonSentences[i]['@end']);
            } else { anyError = true; this.state.actualJob.JsonSentences[i].error = true; }

            if (start < lastnumber) {
                anyError = true; this.state.actualJob.JsonSentences[i].error = true;
            }
            lastnumber = start;
            if (end < lastnumber) {
                anyError = true; this.state.actualJob.JsonSentences[i].error = true;
            }
            lastnumber = end;
        }

        if (anyError) {
            alert('Impossibile salvare le modifiche, ricontrollare i valori numerici inseriti per i millisecondi');
            return;
        }

        let objtosend = {};
        objtosend['annotated-call'] = {};
        objtosend['annotated-call'].annotation = {};
        objtosend['annotated-call'].annotation.type = {};
        objtosend['annotated-call'].annotation.type.sentence = this.state.actualJob.JsonSentences;

        var postdata = objtosend;
        $.ajax({
            type: 'POST',
            url: APPCONFIG.apibaseurl + '/job/import_jsonobject/' + this.state.actualJob.JobGuid,
            headers: {
                'Content-Type': 'application/json',
                'AuthToken': this.props.user.authtoken
            },
            // dataType: 'json',
            data: JSON.stringify(postdata),
            success: function (data) {
                this.setState({ saveDone: true });
            }.bind(this),
            error: function (xhr, status, err) {
                console.error(status, err.toString());
            }.bind(this)
        });
    };

    hideSaveDone() {
        this.setState({ saveDone: false });
        window.location.reload();
    };

    enableModifyState() {
        this.setState({ transModify: true });
    }
    disableModifyState() {
        this.setState({ transModify: false });
    }

    // downloadFileLink(link) {
    //     if (!link)
    //         return;

    //     $.ajax({
    //         type: "GET",
    //         url: link,
    //         async: false,
    //         cache: false,
    //         headers: {
    //             'AuthToken': this.props.user.authtoken
    //         },
    //         success: function (response, status, xhr) {
    //             var type = xhr.getResponseHeader('Content-Type');
    //             var blob = new Blob([response], { type: type });

    //             var URL = window.URL || window.webkitURL;
    //             var downloadUrl = URL.createObjectURL(blob);

    //             var a = document.createElement("a");
    //             a.href = downloadUrl;
    //             a.download = "prova.docx";
    //             document.body.appendChild(a);
    //             a.click();
    //         }.bind(this),
    //         error: function (xhr, status, err) {
    //             console.error(this.props.url, status, err.toString());
    //             return null;
    //         }.bind(this)
    //     });
    // }

    getJobTabContent(job, index, mp4, wav) {
        let ViewBox;
        let ModifyButton;

        if (!this.state.transModify) {
            ViewBox = job.JsonSentences &&
                <TransBox sentences={job.JsonSentences} currentmillisec={this.state.player.currentsec} onWordClick={this.playOnSec}></TransBox>

            ModifyButton = job.Status === 3 && <Button style={{ color: '#00ADEF' }} onClick={this.enableModifyState} color="primary"><Icon>file_upload</Icon> modalità modifica</Button>
        }
        else {
            ViewBox = <div style={{ 'width': '100%', maxHeight: '400px', overflow: 'auto' }}>
                {job.JsonSentences && job.JsonSentences.map((sentence, sentenceIndex) =>
                    <div key={sentenceIndex} className={sentence.error ? "sentenceError" : "sentenceGood"}>
                        <textarea style={{ 'width': '15%', height: '85px', border: '1px solid #ccc', borderRadius: '5px', resize: 'none', marginRight: '1%' }} value={this.state.value} defaultValue={sentence['@start']} onChange={(evt) => this.handleTextareaChange(evt, '@start', sentenceIndex)} />
                        <textarea className={this.state.player.currentsec >= parseInt(sentence['@start']) && this.state.player.currentsec < parseInt(sentence['@end']) ? 'modifycurrentword' : 'notcurrentword'} style={{ 'width': '59%', height: '85px', border: '1px solid #ccc', borderRadius: '5px', resize: 'none' }} value={this.state.value} defaultValue={sentence['#text']} onChange={(evt) => this.handleTextareaChange(evt, '#text', sentenceIndex)} />
                        <textarea style={{ 'width': '15%', height: '85px', border: '1px solid #ccc', borderRadius: '5px', resize: 'none', marginLeft: '1%' }} value={this.state.value} defaultValue={sentence['@end']} onChange={(evt) => this.handleTextareaChange(evt, '@end', sentenceIndex)} />
                        <div style={{ 'width': '5%', float: "right", clear: "both", paddingTop: "20px" }}>
                            <Icon onClick={() => this.addJsonTrans(sentenceIndex, index)} style={{ color: '#00ADEF', cursor: 'pointer' }}>add</Icon><br />
                            <Icon onClick={() => this.removeJsonTrans(sentenceIndex, index)} style={{ color: '#00ADEF', cursor: 'pointer' }}>remove</Icon>
                        </div>
                    </div>
                )}
            </div >
            ModifyButton = job.Status === 3 &&
                <div>
                    <Button style={{ color: '#00ADEF' }} onClick={this.disableModifyState} color="primary"><Icon>book</Icon> modalità lettura</Button>
                    <Button style={{ color: '#00ADEF' }} onClick={this.handleJsonSave} color="primary"><Icon>save</Icon> salva</Button>
                </div>
        }
        return <div key={index} className="tabcontent">
            <Row>
                <Col xs={6}>
                    {ModifyButton}
                </Col>
                <Col xs={6} style={{ 'textAlign': 'right' }}>
                    {job.Status === 3 && <Button style={{ color: '#00ADEF' }} onClick={this.handleDeleteDialogRequestOpen} color="primary"><Icon>delete</Icon> elimina</Button>}
                </Col>
            </Row>
            <Divider style={{ 'marginBottom': '15px' }} />
            {ViewBox}
            <Divider style={{ 'marginTop': '15px' }} />
            <div>
                <Row>
                    <Col xs={12} style={{ 'textAlign': 'right' }}>
                        {job.Status === 3 && <Button style={{ color: '#00ADEF' }} href={job.XMLFileLink} color="primary"><Icon>file_download</Icon> xml</Button>}
                        {job.Status === 3 && <Button style={{ color: '#00ADEF' }} href={job.DOCFileLink} color="primary"><Icon>file_download</Icon> word</Button>}
                        {job.Status === 3 && <Button style={{ color: '#00ADEF' }} href={job.SRTFileLinkByJson} color="primary"><Icon>file_download</Icon> srt</Button>}
                        {job.Status === 3 && <Button style={{ color: '#00ADEF' }} href={job.SRTFileLink} color="primary"><Icon>file_download</Icon> srt (orig.)</Button>}
                    </Col>
                </Row>
            </div>
            <Dialog open={this.state.saveDone}>
                <DialogTitle className="dialogtitle">Salvataggio</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ textAlign: 'center', minWidth: '300px', padding: '20px 10px' }}>
                        Salvataggio avvenuto correttamente
                    </DialogContentText>
                    <DialogActions>
                        <Button style={{ marginTop: 10 }} raised color="primary" onClick={this.hideSaveDone}>chiudi</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </div >
    };

    componentDidMount() {
        if (this.props.fileGuid !== '') {
            this.loadFileInfo();
            this.loadSpeechModeCode();
            this.loadJobs();
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if ((this.props.fileGuid !== '') && (prevProps.fileGuid !== this.props.fileGuid)) {
            let player = Object.assign({}, this.state.player, { currentsec: 0, startsec: 0 });
            this.setState({ slideIndex: 0, player: player });
            this.loadFileInfo();
            if (!this.state.intervalenabled)
                this.loadJobs();
        }
    };

    componentWillUnmount() {
        //clearInterval(this.updJobsTimerID);
    };

    addJsonTrans(sentenceIndex, jobIndex) {
        let job = Object.assign({}, this.state.jobs[jobIndex]);
        job.JsonSentences.splice(sentenceIndex, 0, { '@start': '0', '#text': '', '@end': '0' });
        this.setState({ job });

        this.setState({ transModify: false });
        setTimeout(function (context) { context.setState({ transModify: true }) }, 1, this);

    }
    removeJsonTrans(sentenceIndex, jobIndex) {
        let job = Object.assign({}, this.state.jobs[jobIndex]);
        job.JsonSentences.splice(sentenceIndex, 1);
        this.setState({ job });

        this.setState({ transModify: false });
        setTimeout(function (context) { context.setState({ transModify: true }) }, 1, this);
    }

    render() {
        const currentinfo = this.state.fileinfo;
        const filejobs = this.state.jobs;
        const selectedjobid = this.state.slideIndex === filejobs.length ? '' : filejobs[this.state.slideIndex].JobGuid;
        const actualJob = this.state.actualJob;

        if (!currentinfo || !this.props.fileGuid || (this.props.fileGuid === '') || (currentinfo === '') || (currentinfo.FileGuid && (this.props.fileGuid !== currentinfo.FileGuid)))
            return null;
        return (
            <div>
                <ReactInterval timeout={APPCONFIG.updfuncfreq} enabled={this.state.intervalenabled} callback={this.loadJobs} />
                <div>
                    <div className="mediatitle">
                        <h3>{currentinfo.Tilte ? currentinfo.Tilte : currentinfo.Name}</h3>
                        <Divider style={{ marginBottom: 10 }} />
                    </div>
                    <div className="tagslist" style={{ display: 'none' }}>
                        <Chip label='musica' onRequestDelete={this.handleRequestTagDelete} />
                        <Chip label='grass' onRequestDelete={this.handleRequestTagDelete} />
                    </div>
                    <Paper elevation={2}>
                        {
                            actualJob &&
                            <VideoPlayer
                                videoSrc={currentinfo.MP4FileLink}
                                videoTitle={currentinfo.Name}
                                startSec={this.state.player.startsec}
                                onCurrentSecChange={this.handleCurrentSecChange}
                                sentences={actualJob.JsonSentences}
                                sentencesUrl={actualJob.VTTFileLinkByJson}
                            ></VideoPlayer>
                        }
                    </Paper>
                </div>
                <div>
                    <Paper elevation={1}>
                        <AppBar position="static" color="primary">
                            <Tabs id="transtab" scrollable
                                scrollButtons="on" onChange={this.handleTabChange} value={this.state.slideIndex}>
                                {
                                    filejobs && (typeof filejobs !== undefined) &&
                                    filejobs.map((job, index) => this.getJobTabHeader(job, index))
                                }
                                <Tab value={filejobs.length > 0 ? filejobs.length : 0} icon={<Icon className="material-icons">add</Icon>} label="Nuova Trascrizione" >
                                </Tab>
                            </Tabs>
                        </AppBar>
                        {
                            filejobs && (typeof filejobs !== undefined) &&
                            filejobs.map((job, index) => this.state.slideIndex === index && this.getJobTabContent(job, index, currentinfo.MP4FileLink, currentinfo.WAVFileLink))
                        }
                        {
                            this.state.slideIndex === filejobs.length &&
                            <div className="tabcontent">
                                <NewTrans fileGuid={this.props.fileGuid} user={this.props.user} onNewTransAdded={this.handleTransAdded}></NewTrans>
                            </div>
                        }
                    </Paper>
                </div>
                <DeleteDialog open={this.state.deletedialogopen} user={this.props.user} objectid={selectedjobid} objecttype='job' onRequestClose={this.handleDeleteDialogRequestClose} />
            </div >
        );
    }
}

MediaInfo.propTypes = {
    user: PropTypes.object.isRequired,
    fileGuid: PropTypes.string.isRequired
};

export default MediaInfo;