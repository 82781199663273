import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MediaCard from './MediaCard';
import { Row, Col, Visible, Hidden, ScreenClassRender } from 'react-grid-system';
import ReactInterval from 'react-interval';
import APPCONFIG from '../data/appconfig';
import $ from 'jquery';

import '../css/mediacardslist.css';

class MediaCardsList extends Component {
    constructor(props) {
        super(props);
        this.state = { medialist: [], intervalenabled: true };
        this.loadFileList = this.loadFileList.bind(this);
    };

    loadFileList() {
        $.ajax({
            url: APPCONFIG.apibaseurl + '/file/list',
            headers: {
                'Content-Type': 'application/json',
                'AuthToken': this.props.user.authtoken
            },
            success: function (data) {
                if (data.file) {
                    let filetoupdate = 0;
                    const currentStateFiles = this.state.medialist;
                    for (var i = 0; i < currentStateFiles.length; i++) {
                        if (currentStateFiles[i].Status < 3)
                            filetoupdate++;
                    }
                    /*if (filetoupdate === 0) {
                        if (this.state.intervalenabled)
                            this.setState({ intervalenabled: false });
                    }
                    else
                        this.setState({ intervalenabled: true });
                        */
                    if (filetoupdate > 0 || this.state.medialist.length !== data.file.length) {
                        this.setState({ medialist: data.file });
                        if (this.props.setMediaCount)
                            this.props.setMediaCount(data.file.length);
                    }
                }
            }.bind(this),
            error: function (xhr, status, err) {
                console.error(this.props.url, status, err.toString());
            }
        });
    };

    componentDidMount() {
        this.loadFileList();
    };

    componentWillUnmount() {
        //clearInterval(this.timerID);
    };

    componentDidUpdate(prevProps, prevState) {
        if (!this.state.intervalenabled)
            this.loadFileList();
    };

    render() {
        const medialist = this.state.medialist;
        return (
            <div>
                <ReactInterval timeout={APPCONFIG.updfuncfreq} enabled={this.state.intervalenabled} callback={this.loadFileList} />
                {medialist.length === 0 && <Col xs={12}><div className="noresults">Nessun file ancora caricato</div></Col>}
                {medialist.length > 0 && !this.props.showVerticalList &&
                    <Row> {
                        medialist.map(media =>
                            <Col key={media.FileGuid} xs={12} sm={6} md={3} lg={2}>
                                <MediaCard media={media} displayMenuIcon={true} user={this.props.user} reloadFunction={this.loadFileList} />
                            </Col>
                        )}
                    </Row>
                }
                {medialist.length > 0 && this.props.showVerticalList &&
                    medialist.map(media =>
                        (media.Status === 3 &&
                            <Row key={media.FileGuid} className={media.FileGuid === this.props.selectedMediaId ? 'rowselected' : ''}>
                                <Col xs={12}>
                                    <MediaCard media={media} displayMenuIcon={media.FileGuid !== this.props.selectedMediaId} user={this.props.user} twoColumnLayout reloadFunction={this.loadFileList} />
                                </Col>
                            </Row>
                        )
                    )
                }
            </div>
        );
    }
}

MediaCardsList.propTypes = {
    user: PropTypes.object.isRequired,
    setMediaCount: PropTypes.func,
    onSelectMedia: PropTypes.func,
    selectedMediaId: PropTypes.string,
    showVerticalList: PropTypes.bool
};

export default MediaCardsList;