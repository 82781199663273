import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog, {
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from 'material-ui/Dialog';
import Button from 'material-ui/Button';
import Icon from 'material-ui/Icon';
import IconButton from 'material-ui/IconButton';
import { LinearProgress, CircularProgress } from 'material-ui/Progress';
import Input from 'material-ui/Input';
import InputLabel from 'material-ui/Input/InputLabel';
import FormControl from 'material-ui/Form/FormControl';
import FormHelperText from 'material-ui/Form/FormHelperText';
import APPCONFIG from '../data/appconfig';
import $ from 'jquery';

//https://github.com/wubocong/react-upload-file
//https://github.com/SoAanyip/React-FileUpload

class FileInputDialog extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: true, newfileguid: '', filetitle: '', error: '' };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleRequestClose = this.handleRequestClose.bind(this);
        this.handleFileTitleChange = this.handleFileTitleChange.bind(this);
        this.handleClearTitle = this.handleClearTitle.bind(this);
    }

    handleClearTitle() {
        this.setState({ filetitle: '' });
    };

    handleRequestClose() {
        //TODO aggiornare il titolo
        let postdata = { file: [{ FileGuid: this.state.newfileguid, Name: this.state.filetitle }] };
        $.ajax({
            type: 'POST',
            url: APPCONFIG.apibaseurl + '/file/setfilename',
            headers: {
                'Content-Type': 'application/json',
                'AuthToken': this.props.user.authtoken
            },
            dataType: 'json',
            data: JSON.stringify(postdata),
            success: function (data) {
                this.props.onRequestClose();
            }.bind(this),
            error: function (xhr, status, err) {
                console.error(status, err.toString());
            }
        });
    };

    handleFileTitleChange(event) {
        const error = event.target.value !== '' ? '' : 'Inserire un titolo valido';
        this.setState({
            filetitle: event.target.value,
            error: error
        });
    };

    handleSubmit() {
        this.state = { loading: true, newfileguid: '', filetitle: '', error: '' };

        setTimeout(function (context) {
            var blob = context.props.filetoupload;

            const BYTES_PER_CHUNK = 5242880; // 5Mb chunk sizes.
            const SIZE = blob.size;
            var start = 0;
            var end = 0;
            var responseGuid;
            var response;
            while (start < SIZE) {
                end = (start + BYTES_PER_CHUNK) > blob.size ? blob.size : start + BYTES_PER_CHUNK;
                var chunk = blob.slice(start, end);
                response = context.handleUpload(chunk, start, end - 1, blob.size, blob.name);
                start = end;

                if (response.status === 200 && response.responseJSON === 100) continue;
                else if (response.status === 200 && response.responseJSON.file.length > 0) {
                    responseGuid = response.responseJSON.file[0].FileGuid;
                }
                else { console.log('errore della risposta'); }
            }

            let filename = blob.name.substring(0, context.props.filetoupload.name.indexOf('.'))
            context.setState({ loading: false, newfileguid: responseGuid, filetitle: filename });

            console.log(response);
        }, 100, this);
    };

    handleUpload(blobFile, start, end, total, name) {
        var data = new FormData();
        var imagedata = blobFile;
        data.append('data', imagedata);

        var response =
            $.ajax({
                url: APPCONFIG.apibaseurl + "/uploadChunks",
                type: "POST",
                async: false,
                cache: false,
                data: data,
                headers: {
                    'AuthToken': this.props.user.authtoken,
                    'Content-Range': 'bytes ' + start + '-' + end + '/' + total,
                    'Content-Disposition': 'form-data; name="data"; filename="' + name + '"'
                },
                processData: false,
                contentType: false
            });

        return response;

        // var data = new FormData();
        // var imagedata = blobFile;
        // data.append("data", imagedata);

        // $.ajax({
        //     url: APPCONFIG.apibaseurl + "/uploadChunks",
        //     type: "POST",
        //     async: false,
        //     cache: false,
        //     data: data,
        //     headers: {
        //         'AuthToken': this.props.user.authtoken
        //         //'Content-Length': this.state.content_lenght
        //     },
        //     processData: false,
        //     contentType: false,

        //     success: function (respData) {
        //         return respData;
        //         // let filename = this.props.filetoupload.name.substring(0, this.props.filetoupload.name.indexOf('.'))
        //         // this.setState({ loading: false, newfileguid: data.file[0].FileGuid, filetitle: filename });
        //     }.bind(this),
        //     error: function (xhr, status, err) {
        //         // do stuff
        //     }
        // });
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.filetoupload !== null)
            return true;
        else
            return false;
    }

    componentDidMount() {
        if (this.props.filetoupload !== null)
            this.handleSubmit();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.filetoupload !== null && (prevProps.filetoupload === null || this.props.filetoupload.id !== prevProps.filetoupload.id))
            this.handleSubmit();
    };

    render() {
        if (!this.props.filetoupload)
            return null;
        let fileloadinf = this.state.loading;
        return (
            <Dialog open={this.props.open}>
                <DialogTitle className="dialogtitle">{"Aggiungi nuovo file"}</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ textAlign: 'center', minWidth: '300px', padding: '20px 10px' }}>
                        {fileloadinf &&
                            <div>
                                <div className='alertmsg'>Caricamento in corso</div>
                                <CircularProgress />
                            </div>}
                        {!fileloadinf &&
                            <div>
                                <div>
                                    <FormControl error={this.state.error !== ''} style={{ width: '100%' }} margin='normal'>
                                        <InputLabel htmlFor="filetitle">Titolo file</InputLabel>
                                        <Input id="filetitle" onChange={this.handleFileTitleChange} value={this.state.filetitle} />
                                        <IconButton className="textfieldbtn" color="default" aria-label="cancella titolo" title="cancella titolo" onClick={this.handleClearTitle}>
                                            <Icon className="textfieldbtnicon">clear</Icon>
                                        </IconButton>
                                        <FormHelperText>{this.state.error}</FormHelperText>
                                    </FormControl>
                                </div>
                                <Button style={{ marginTop: 10 }} raised disabled={this.state.error !== '' || this.state.filetitle.length < 2} color="primary" onClick={this.handleRequestClose} >
                                    Salva
                                </Button>
                                <Button style={{ marginTop: 10 }} raised color="default" onClick={this.props.onRequestClose} >
                                    chiudi
                                </Button>
                            </div>}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

FileInputDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired,
    filetoupload: PropTypes.object,
    onRequestClose: PropTypes.func
};

export default FileInputDialog;