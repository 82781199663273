import React, { Component } from 'react';
import PropTypes from 'prop-types';

class TransBox extends Component {
    render() {
        const tollerance = 500;
        return (
            <span className="trans-sentence">
                {this.props.sentences && this.props.sentences.map((sentence, sentenceIndex) =>
                    <p key={sentenceIndex} className={(this.props.currentmillisec >= (parseInt(sentence['@start']) - tollerance)) && (this.props.currentmillisec <= parseInt(sentence['@end'])) ? 'trans-item currentword' : 'trans-item'}
                        start={parseInt(sentence['@start'])} end={parseInt(sentence['@end'])} onClick={this.props.onWordClick}>{sentence['#text']}
                    </p>
                )}
            </span>
        )
    }
}

TransBox.propTypes = {
    sentences: PropTypes.array.isRequired,
    currentmillisec: PropTypes.number.isRequired,
    onWordClick: PropTypes.func.isRequired
};

export default TransBox;